import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { ITransitionEffectItem } from "@/stores/mixCutDetailStore/type/transition";
import useMixCutDetailSave from "./useMixCutDetailSave";

export interface IGlobalTransitionReturn {
  globalTransition: { effects: ITransitionEffectItem[]; duration: number };
  handleAddTransitionItem: (item: ITransitionEffectItem) => void;
  handleChangeDuration: (time: number) => void;
  handleRemoveTransitionItem: (item: ITransitionEffectItem) => void;
  handleClearGlobalTransitionItem: () => void;
}

const useGlobalTransition = (): IGlobalTransitionReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const handleAddTransitionItem = (item: ITransitionEffectItem) => {
    const globalTransition =
      mixCutDetailStore.getProjectGlobalTransition().effects;
    const durationTransition =
      mixCutDetailStore.getProjectGlobalTransition().duration;
    globalTransition.push(item);
    mixCutDetailStore.setProjectGlobalTransition({
      duration: durationTransition,
      effects: globalTransition,
    });
    handleSaveMixCutDetailSave();
  };

  const handleClearGlobalTransitionItem = () => {
    const globalTransitionItem = mixCutDetailStore.getProjectGlobalTransition();
    globalTransitionItem.effects = [];
    mixCutDetailStore.setProjectGlobalTransition({ ...globalTransitionItem });
  };

  const handleRemoveTransitionItem = (item: ITransitionEffectItem) => {
    const newGlobalTransition = [
      ...mixCutDetailStore.getProjectGlobalTransition().effects,
    ];
    const index = mixCutDetailStore
      .getProjectGlobalTransition()
      .effects.findIndex((step) => item.sub_type === step.sub_type);
    const durationTransition =
      mixCutDetailStore.getProjectGlobalTransition().duration;
    newGlobalTransition.splice(index, 1);
    mixCutDetailStore.setProjectGlobalTransition({
      duration: durationTransition,
      effects: newGlobalTransition,
    });
    handleSaveMixCutDetailSave();
  };

  const handleChangeDuration = (e: number) => {
    const newGlobalTransition = {
      ...mixCutDetailStore.getProjectGlobalTransition(),
    };
    mixCutDetailStore.setProjectGlobalTransition({
      duration: e,
      effects: newGlobalTransition.effects,
    });
    handleSaveMixCutDetailSave();
  };

  return {
    globalTransition: mixCutDetailStore.getProjectGlobalTransition(),
    handleAddTransitionItem,
    handleRemoveTransitionItem,
    handleChangeDuration,
    handleClearGlobalTransitionItem,
  };
};

export default useGlobalTransition;
