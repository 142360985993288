import { makeObservable, observable, action } from "mobx";
import { ICaption } from "../mixCutDetailStore/type/caption";
import { ITitleItem } from "../mixCutDetailStore/type/title";
import { IMaterialFile } from "../mixCutDetailStore/type";

export interface IBackground {
  color: string;
  image_url: string;
}

class PreviewConfig {
  private material: IMaterialFile;
  private caption: ICaption;
  private title: ITitleItem;
  public version = 0;
  private background: IBackground;

  constructor() {
    makeObservable(this, {
      version: observable, // 只监听 version
      setCaption: action,
      setTitle: action,
      setBackground: action,
      setMaterial: action,
    });
    this.material = null;
    this.caption = null;
    this.background = {
      color: "",
      image_url: "",
    };
    this.title = null;
  }

  public getVersion() {
    return this.version;
  }

  public getMaterial() {
    return this.material;
  }

  public getCaption() {
    return this.caption;
  }

  public getTitle() {
    return this.title;
  }

  public getBackground() {
    return this.background;
  }

  public setCaption(caption: ICaption, disAllowUpdate = false) {
    if (!disAllowUpdate) {
      this.version = Date.now() + Math.random() * 100;
    }
    this.caption = caption;
  }

  public setTitle(title: ITitleItem, disAllowUpdate = false) {
    if (!disAllowUpdate) {
      this.version = Date.now() + Math.random() * 100;
    }
    this.title = title;
  }

  public setBackground(bg: IBackground) {
    this.version = Date.now();
    this.background = bg;
  }

  public setMaterial(material: IMaterialFile, disAllowUpdate = false) {
    if (!disAllowUpdate) {
      this.version = Date.now();
    }
    this.material = material;
  }

  public setStore(
    material: IMaterialFile,
    caption: ICaption,
    title: ITitleItem,
  ) {
    this.version = Date.now() + Math.random() * 100;
    this.material = material;
    this.caption = caption;
    this.title = title;
  }
}

const previewConfig = new PreviewConfig();
export default previewConfig;
