import { CreateEngineeringResponse } from "@/services/createSpace/engineering";
import { makeAutoObservable } from "mobx";
import { IMaterialPlayInfo, ISences } from "./type";
import { GlobalCaption, ICaption } from "./type/caption";
import { ITransition, ITransitionEffectItem } from "./type/transition";
import { ITitle } from "./type/title";
import {
  IBgResponse,
  ICoverItemResponse,
  IMusicResponse,
} from "@/services/mixCutDetail/mixCutDetail";

class MixCutDetailStore {
  private version = null;
  public outputCount = 100;
  private projectOriginData: any = null;
  private projectMsg: CreateEngineeringResponse = null;
  private projectMaterialsInfo: IMaterialPlayInfo = {};
  private projectSences: ISences[];
  private projectCaption: ICaption[][];
  private projectTitle: ITitle;
  private transition: ITransition;
  private projectCover: ICoverItemResponse[];
  private projectBgColor: string;
  private projectBgAudioVolumn: number;
  private projectBgAudio: IMusicResponse[] = [];
  private projectBgImages: IBgResponse[] = [];
  private projectIsGlobal = false;
  private projectGlobalCaption: GlobalCaption = [];
  private projectGlobalTransition: {
    effects: ITransitionEffectItem[];
    duration: number;
  } = { duration: 500, effects: [] };
  constructor() {
    makeAutoObservable(this);
  }
  public getVerison() {
    return this.version;
  }

  public setVersion(version: string) {
    this.version = version;
  }
  public getProjectMaterialsInfo() {
    return this.projectMaterialsInfo;
  }

  public getProjectOrigin() {
    return this.projectOriginData;
  }

  public getProjectMsg() {
    return this.projectMsg;
  }

  public setProjectMsg = (projectMsg: CreateEngineeringResponse) => {
    this.projectMsg = projectMsg;
  };

  public getProjectSences() {
    return this.projectSences;
  }

  public getProjectTitle() {
    return this.projectTitle;
  }

  public getProjectCover() {
    return this.projectCover;
  }

  public getProjectBgColor() {
    return this.projectBgColor;
  }

  public getProjectBgAudio() {
    return this.projectBgAudio;
  }

  public getProjectBgImages() {
    return this.projectBgImages;
  }

  public getProjectIsGlobale() {
    return this.projectIsGlobal;
  }

  public getProjectGlobalCaptions() {
    return this.projectGlobalCaption;
  }

  public getProjectGlobalTransition() {
    return this.projectGlobalTransition;
  }

  public getProjectBgAudioVolum() {
    return this.projectBgAudioVolumn;
  }

  public setProjectBgAudioVolum(volumn: number) {
    this.projectBgAudioVolumn = volumn;
  }

  public setProjectMaterialsInfo(e: IMaterialPlayInfo) {
    this.projectMaterialsInfo = e;
  }

  public setProjectSences = (projectSences: ISences[]) => {
    if (Array.isArray(projectSences)) {
      this.projectSences = projectSences;
    } else {
      console.error("Invalid projectSences format");
    }
  };

  public getProjectCaption() {
    return this.projectCaption;
  }

  public setProjectOriginData(e: any) {
    this.projectOriginData = e;
  }

  public setProjectCaption(projectCation: ICaption[][]) {
    this.projectCaption = projectCation;
  }

  public getTransition() {
    return this.transition;
  }
  public setTransition(transition: ITransition) {
    this.transition = transition;
  }

  public setProTitle(data: ITitle) {
    this.projectTitle = data;
  }

  public setProjectCover(cover: ICoverItemResponse[]) {
    this.projectCover = [...cover];
  }

  public setProjectBgColor(color: string) {
    this.projectBgColor = color;
  }

  public setProjectBgAudio(bgAudio: IMusicResponse[]) {
    this.projectBgAudio = [...bgAudio];
  }

  public setProjectBgImages(bgImages: IBgResponse[]) {
    this.projectBgImages = bgImages;
  }

  public setProjectIsGolbal(e: boolean) {
    this.projectIsGlobal = e;
  }
  public setProjectGlobalCaptions(e: GlobalCaption) {
    this.projectGlobalCaption = [...e];
  }

  public setProjectGlobalTransition(e: {
    effects: ITransitionEffectItem[];
    duration: number;
  }) {
    this.projectGlobalTransition = e;
  }
}

const mixCutDetailStore = new MixCutDetailStore();
export default mixCutDetailStore;
