import http from "@/utils/http";
import axios from "axios";
import { IAliOssToken } from "./fileUpload/fileUpload";
/**
 * 查找当前团队的所有权益
 * **/
export async function teme_package(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/package", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 移交管理员
 * */
export async function team_transfer(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/transfer", params);
    console.log(params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 查询交易记录
 * */
export async function transaction_record_api(payload): Promise<any> {
  const { start=0, end=0, type=0, sales_agent_id=0, page, page_size, team_id} = payload;
  try {
    const res = await http.post(
      "/api/v1/graphql/",
      `{
        transaction_record(start:${start}, end:${end}, type:${type}, sales_agent_id:${sales_agent_id}, page:${page}, page_size:${page_size}, team_id:${team_id}) {
          items {
            trans_num
            benefit_name
            sales_agent_id
            sales_agent_name
            amount
            pay_type
            created_at
            transaction_type
          }
          total
        }
      }`,{
        headers: {
            "Content-type": "application/graphql",
        },
    });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 获取团队权益流水
 * */
export async function equity_flow_api(params): Promise<any> {
  // const { start=0, end=0, type=0, field, page, page_size, team_id} = payload;
  try {
    const res = await http({
      url: '/api/v1/team/package/benefit/flow',
      params,
    });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 获取团队权益流水
 * */
// export async function equity_flow_api(payload): Promise<any> {
//   const { start=0, end=0, type=0, field, page, page_size, team_id} = payload;
//   try {
//     const res = await http.post(
//       "/api/v1/graphql/",
//       `{
//         benefit_flow(start:${start}, end:${end}, type:${type}, field:"${field || ""}", page:${page}, page_size:${page_size}, team_id:${team_id}) {
//           items {
//             trans_num
//             team_id
//             benefit_type
//             change_value
//             app_type
//             operator_uuid
//             operator_name
//             trande_type
//             left_value
//             created_at
//           }
//           total
//         }
//       }`, {
//         headers: {
//             "Content-type": "application/graphql",
//         },
//     });
//     if (res) {
//       return res;
//     } else {
//       console.log("接口错误");
//     }
//   } catch (err) {
//     return err;
//   }
// }

// 获取销售列表
export async function getUserListApi(params={}): Promise<any> {
  console.log(params, 'paramsparams')
  try {
    const res = await http({
      url: "/api/v1/admin/user",
      params
    });
    if (res) {
      return res;
    } else {
      return Promise.reject()
    }
  } catch (err) {
    return Promise.reject(err);
  }
}
// 获取账号列表
// export async function getNameListApi(params={}): Promise<any> {
//   console.log(params, 'paramsparams')
//   try {
//     const res = await http({
//       url: "/api/v1/user/name/info",
//       params
//     });
//     if (res) {
//       return res;
//     } else {
//       return Promise.reject()
//     }
//   } catch (err) {
//     return Promise.reject(err);
//   }
// }
/**
 *
 * 企业认证
 * */
export async function team_corporate(params?): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/company/info", params);
    console.log(params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 获取企业认证
 * */
export async function team_corporate_get(params?): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/company/info");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 查询与销售想拼配的 二维码
 * */
export async function user_wechat_qrcode(): Promise<any> {
  try {
    const res = await http.get("/api/v1/user/wechat_qrcode");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 上传文件 企业 认证
 */
export const getDocumentUploadToken = async (name: string, size: number) => {
  try {
    const result = await http.post(`/api/v1/space/oss/upload`, {
      name: name,
      method: "file",
      size,
    });
    return result?.data;
  } catch (error) {
    console.error("Error fetching upload token:", error);
    throw error;
  }
};
/**
 *
 * 上传文件 企业 认证 oss
 */
export const setFile_teamOverview = async (
  token: IAliOssToken,
  file: File,
): Promise<void> => {
  const newFormData = new FormData();

  newFormData.append("key", `${token.dir + file.name}`);
  newFormData.append("callback", token.callback);
  newFormData.append("OSSAccessKeyId", token.accessid);
  newFormData.append("policy", token.policy);
  newFormData.append("Signature", token.signature);
  newFormData.append("expire", token.expire);
  newFormData.append("success_action_status", "200");
  newFormData.append("Content-Disposition", `attachment`);
  newFormData.append("file", file);

  const response = await http.post(token.host, newFormData, {
    timeout: 100000000,
    headers: {
      "Content-Type": "multipart/form-data",
      a: "ccc",
    },
  });
  return response.data;
  // if (response.status === 200) {
  //     // 成功后的逻辑

  // }
};

/**
 *
 *  上传企业认证 进行 ocr认证
 * */
export async function team_license_ocr(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/company/license/ocr", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 *  获取可切换的所有团队
 * */
export async function team_addop(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 *  修改头像
 * */
export async function patchTeamLogApi(data): Promise<any> {
  try {
    const res = await http.put("/api/v1/team/", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

export async function team_company_info(): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/company/info");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

export async function setTeamName(data): Promise<any> {
  try {
    const res = await http.put("/api/v1/team/", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
