/* eslint-disable no-prototype-builtins */
/* eslint-disable no-constant-condition */
import http from "@/utils/http";

import { getTokenToLocalStorage } from "@/utils/token";

// 创建脚本
export async function createScript(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/script/", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
export async function getTeamDetail(): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/detail");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 爆款脚本列表
function convertParamsToQueryString(params) {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      // 跳过未定义的键值
      if (value === undefined || value === null) {
        continue;
      }

      // 如果值是数组，逐个添加
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((item) => {
          if (item !== undefined && item !== null && item !== "") {
            queryParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
            );
          }
        });
      }
      // 如果值是字符串且包含逗号，则拆分并逐个添加
      else if (typeof value === "string" && value.includes(",")) {
        value.split(",").forEach((item) => {
          const trimmedItem = item.trim();
          if (trimmedItem !== "") {
            queryParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(trimmedItem)}`,
            );
          }
        });
      }
      // 如果值是非空的字符串或其他类型，直接添加
      else if (value !== "") {
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
      // 如果值是空字符串，添加键但不添加值
      else {
        queryParts.push(`${encodeURIComponent(key)}=`);
      }
    }
  }

  return queryParts.join("&");
}
export async function getScriptList(params): Promise<any> {
  try {
    const res = await http.get(
      `/api/v1/script/hot?${convertParamsToQueryString(params)}`,
    );
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 行业标签
export async function getScriptIndustry(): Promise<any> {
  try {
    const res = await http.get("/api/v1/script/hot/industry");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
// 产品标签
export async function getScriptProduct(): Promise<any> {
  try {
    const res = await http.get("/api/v1/script/hot/product");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
// 风格标签
export async function getScriptTag(): Promise<any> {
  try {
    const res = await http.get("/api/v1/script/hot/tag");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getProjectList(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/space/project", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function editScript(params): Promise<any> {
  const id = params.id;
  delete params.id;
  try {
    const res = await http.put(`/api/v1/script/${id}`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

export const getScriptCopywriter = async (value, signal) => {
  const token = getTokenToLocalStorage();

  const readAndProcessResponse = async (response) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let buffer = "";

    async function readLine() {
      const contentType = response.headers.get("Content-Type");
      if (contentType.startsWith("application/json")) {
        const resp = await response.json();
        return resp;
      }
      const result = null;
      const followUps = [];
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          if (buffer) {
            // console.log("剩余行:", buffer);
          }
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        const lines = buffer.split("\n");
        buffer = lines.pop();
        for (const line of lines) {
          if (line) {
            let data: any = line.split("data:")[1];
            if (data) {
              data = JSON.parse(data);
              if (data.type == "answer") {
                return {
                  data: JSON.parse(data.content).map((v, i) => ({
                    strings: [v],
                    key: i,
                  })),
                };
              }
            }
          }
        }
      }
      if (result) {
        return {
          data: result.data,
        };
      }
      return null;
    }
    return readLine();
  };
  try {
    const response = await fetch(`/api/v1/script/copywriter`, {
      method: "POST",
      headers: {
        Token: `${token}`,
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(value),
      signal,
    });

    return await readAndProcessResponse(response);
  } catch (e) {
    console.error("请求出错:", e);
    return null;
  }
};
function objectToQueryString(params) {
  const queryParts = [];

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        // 如果需要 'iid=3&iid=4' 的格式
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
        );
      });
    } else {
      // 将空字符串表示为两个单引号 ''
      const encodedValue = value === "" ? "" : encodeURIComponent(value);
      queryParts.push(`${encodeURIComponent(key)}=${encodedValue}`);
    }
  });

  return queryParts.join("&");
}

// 获取智能混剪列表
function formatValue(value: any): string {
  if (Array.isArray(value)) {
    const formattedArray = value
      .map((item) => {
        if (typeof item === "string") {
          // 尝试将字符串转换为数字
          const num = Number(item);
          if (!isNaN(num)) {
            return num.toString();
          }
        }
        // 如果不是可以转换为数字的字符串，递归格式化
        return formatValue(item);
      })
      .join(", ");
    return formattedArray == "0" ? "[]" : `[${formattedArray}]`;
  } else if (typeof value === "string") {
    // 转义双引号和反斜杠以避免语法错误
    const escapedString = value.replace(/\\/g, "\\\\").replace(/"/g, '\\"');
    return `"${escapedString}"`;
  } else if (typeof value === "number") {
    return value.toString();
  } else if (typeof value === "boolean") {
    return value ? "true" : "false";
  } else if (typeof value === "object") {
    const formattedObject = Object.entries(value)
      .filter(([_, v]) => v !== undefined && v !== null) // 排除 undefined 和 null
      .map(([k, v]) => `${k}: ${formatValue(v)}`)
      .join(", ");
    return `{ ${formattedObject} }`;
  } else {
    throw new Error(`Unsupported type: ${typeof value}`);
  }
}
function formatParams(params: Record<string, any>): string {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null) // 排除 undefined 和 null
    .map(([key, value]) => `${key}: ${formatValue(value)}`)
    .join(", ");
}
export async function getEditing(params): Promise<any> {
  const query = `  
  {
  hot_session_list(${formatParams(params)}) {
    next_cursor
    items{
      id
      title
      content
      industry_id
      style_id
      aspect
      width
      height
      used_count
      style
      example_video
      example_video_duration
      scene_count
      version
      material_count
      cover_url
      attribute
      strategy
      subtitles
      speakers
      musics
      created_at
      updated_at
      industry
    }
  }
}
  `;

  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res.data.hot_session_list;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 获取所有行业
export async function getEditingIndustry(): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/session/industry");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 获取所有分类
export async function getEditingCategory(): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/session/category");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 获取所有风格
export async function getEditingStyle(): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/session/style");
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getTeamMember(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/team/member/${params}`);
    if (res) {
      return res.data.item;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getEditingInfo(params): Promise<any> {
  const query = `  
  {
  hot_session(id: ${params}){
    id
    title
    content
    industry_id
    style_id
    aspect
    width
    height
    used_count
    style
    example_video
    example_video_duration
    scene_count
    version
    material_count
    cover_url
    attribute
    strategy
    subtitles
    speakers
    musics
    created_at
    updated_at
    industry
  }
}
  `;

  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res.data.hot_session;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
