import { IMaterialFile, IOrigin } from "@/stores/mixCutDetailStore/type";
import { ICaption } from "@/stores/mixCutDetailStore/type/caption";
import { ITitleItem } from "@/stores/mixCutDetailStore/type/title";
import previewConfing, { IBackground } from "@/stores/previewStore";
import { combTimeline } from "@/utils/perm";
import { runInAction, toJS } from "mobx";
import { useCallback, useEffect, useState } from "react";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailSave from "../mixCutDetail/useMixCutDetailSave";
import useMixCutDetail from "../mixCutDetail/useMixCutDetail";
import clipIdStore from "@/stores/previewStore/clipId";
const TAG = "usePreview";
export interface IPreviewData {
  caption: ICaption;
  title: ITitleItem;
  background: IBackground;
  material: IMaterialFile;
}
export interface IConfigText {
  AdaptMode: string;
  Alignment: string;
  Angle: number;
  BorderStyle: number;
  Color: string;
  Content: string;
  Duration: number;
  Font: string;
  FontColor: string;
  FontColorOpacity: number;
  FontFace: { Bold: boolean; Italic: boolean; Underline: boolean };
  FontSize: number;
  Height: number;
  Id: number;
  LineSpacing: number;
  Outline: number;
  SizeRequestType: string;
  Spacing: number;
  TextWidth: number;
  TimelineIn: number;
  TimelineOut: number;
  TrackId: number;
  Type: string;
  X: number;
  Y: number;
}

export interface IUsePreviewReturn {
  clipId: number;
  previewCaption: ICaption;
  previewTitle: ITitleItem;
  background: IBackground;
  previewMaterial: IMaterialFile;
  updatePreviewCaptionAndTitle: () => void;
  handleClear: () => void;
  handleClipIdChange: (clipId: number) => void;
  handlePreviewMaterialChange: (material: IMaterialFile) => void;
  handlePreviewCaptionChange: (caption: ICaption) => void;
  handlePreviewTitleChange: (title: ITitleItem) => void;
  handlePreviewBackgroundChange: (background: IBackground) => void;
  getPreviewData: () => IPreviewData;
  toPreviewGetTimeLine: () => any;
  toInitRenderDataSetPreviewData: (data: IOrigin) => void;
  toIdAndConfigSetSetting: (id: number, config: IConfigText) => void;
  handleToChangeVideoIndexSetTitleAndCaption: (videoGroupIndex: number) => void;
  handleCaptionsBtnClick: (videoGroup: number) => void;
  handleTitleBtnClick: (videoGroup: number) => void;
  handleVideoHeaderBtnClick: (videoGroup: number) => void;
  handlePreviewStore: (
    material: IMaterialFile,
    caption: ICaption,
    title: ITitleItem,
  ) => void;
  findMaterialSetPreview: () => void;
}

const usePreviewConfig = (): IUsePreviewReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [taskId, setTaskId] = useState<any>(null);

  const handlePreviewStore = (
    material: IMaterialFile,
    caption: ICaption,
    title: ITitleItem,
  ) => {
    previewConfing.setStore(material, caption, title);
  };

  const handleClear = () => {
    previewConfing.setCaption(null);
    previewConfing.setTitle(null);
    // previewConfing.setClipId(null);
  };

  const handleClipIdChange = (clipId: number) => {
    clipIdStore.setClipId(clipId);
  };

  const handlePreviewCaptionChange = (caption: ICaption) => {
    previewConfing.setCaption(caption);
  };

  const updatePreviewCaptionAndTitle = () => {
    updatePreviewCaption();
  };

  const updatePreviewTitle = () => {
    const titleId = previewConfing.getTitle().id;
    const title = mixCutDetailStore
      .getProjectTitle()
      .find((item) => item.id === titleId);
    previewConfing.setTitle(title);
  };

  const updatePreviewCaption = () => {
    const captionId = previewConfing.getCaption().id;
    const caption = mixCutDetailStore
      .getProjectCaption()
      .flat(2)
      .find((item) => item.id === captionId);
    previewConfing.setCaption(caption);
  };

  const handlePreviewTitleChange = (title: ITitleItem) => {
    previewConfing.setTitle(title);
  };

  const handlePreviewBackgroundChange = (bg: IBackground) => {
    previewConfing.setBackground(bg);
  };

  const handlePreviewMaterialChange = (mat: IMaterialFile) => {
    previewConfing.setMaterial(mat);
  };

  const getPreviewData = () => {
    return {
      caption: previewConfing.getCaption(),
      title: previewConfing.getTitle(),
      background: previewConfing.getBackground(),
      material: previewConfing.getMaterial(),
    };
  };

  const toPreviewGetTimeLine = useCallback(() => {
    return {
      timeline: combTimeline(
        previewConfing.getMaterial(),
        previewConfing.getCaption(),
        previewConfing.getTitle(),
      ),
    };
  }, [
    previewConfing.getMaterial(),
    previewConfing.getTitle(),
    previewConfing.getCaption(),
  ]);

  const findMaterialSetPreview = () => {
    const material = ((mixCutDetailStore
      .getProjectSences()
      .flat(2)
      .filter((item) => item && item?.materials && item.materials.length > 0) ||
      [])[0]?.materials || [])[0];
    previewConfing.setMaterial(material || null);
  };

  const toInitRenderDataSetPreviewData = (data: IOrigin) => {
    let caption, title;
    const material = ((mixCutDetailStore
      .getProjectSences()
      .flat(2)
      .filter(
        (item) => item && item?.materials && item?.materials.length > 0,
      ) || [])[0]?.materials || [])[0];
    if (!data.global_settings.enabled) {
      caption = ((data?.scenes || [])[0]?.caption || [])[0] || null;
      title = (data?.scenes || [])[0]?.title || null;
    } else {
      caption = (data?.global_settings?.captions || [])[0] || null;
      title = (data?.global_settings?.titles || [])[0] || null;
    }
    console.log("previewConfig", material, caption, title);
    previewConfing.setStore(material, caption, title);
  };

  const toIdAndConfigSetSetting = (id: number, config: IConfigText) => {
    console.log(config);
    if (id === taskId && x === config.X && y === config.Y) return;
    if (isNaN(config?.X) || isNaN(config?.Y)) {
      return;
    }
    console.log("toIdAndConfigSetSetting", config);
    if (!config) return;
    let indexAndType = {
      type: "",
      groupIndex: 0,
      index: 0,
    };
    if (
      !(mixCutDetailStore.getProjectTitle().length > 0) &&
      !(mixCutDetailStore.getProjectCaption().length > 0)
    ) {
      return;
    }
    if (mixCutDetailStore.getProjectIsGlobale()) {
      (mixCutDetailStore.getProjectGlobalCaptions() || []).forEach(
        (item, index) => {
          if (item.id === id) {
            indexAndType = {
              type: "global",
              groupIndex: 0,
              index: index,
            };
          }
        },
      );
    } else {
      (mixCutDetailStore.getProjectCaption() || []).forEach((item, index) => {
        (item || []).forEach((cItem, cIndex) => {
          if (cItem.id === id) {
            indexAndType = {
              type: "caption",
              groupIndex: index,
              index: cIndex,
            };
          }
        });
      });
    }

    (mixCutDetailStore.getProjectTitle() || []).forEach((item, index) => {
      (item?.content || []).forEach((tItem, tIndex) => {
        if (tItem.id === id) {
          indexAndType = {
            type: "title",
            groupIndex: index,
            index: tIndex,
          };
        }
      });
    });

    if (indexAndType.type === "global") {
      const captions = mixCutDetailStore.getProjectGlobalCaptions();
      const item = captions[indexAndType.index];
      if (
        item.text_setting.x === config?.X &&
        item.text_setting.y === config?.Y
      ) {
        return;
      }
      item.text_setting = {
        ...item.text_setting,
        x: config?.X || 0,
        y: config?.Y || 0,
      };
      if (item.text_setting.is_global) {
        captions.forEach((item) => {
          item.text_setting.x = config?.X;
          item.text_setting.y = config?.Y;
        });
      }
      mixCutDetailStore.setProjectGlobalCaptions([
        ...mixCutDetailStore.getProjectGlobalCaptions(),
      ]);
      previewConfing.setCaption(item, true);
      handleSaveMixCutDetailSave();
    }

    if (indexAndType.type === "caption") {
      const captions = mixCutDetailStore.getProjectCaption();
      const item = captions[indexAndType.groupIndex][indexAndType.index];
      if (
        item.text_setting.x === config?.X &&
        item.text_setting.y === config?.Y
      ) {
        return;
      }
      item.text_setting = {
        ...item.text_setting,
        x: config?.X || 0,
        y: config?.Y || 0,
        font_size: config?.FontSize + 12
      };
      if (item.text_setting.is_global) {
        captions.forEach((captionGroup) => {
          captionGroup.forEach((item) => {
            item.text_setting.x = config?.X || 0;
            item.text_setting.y = config?.Y || 0;
          });
        });
      }
      mixCutDetailStore.setProjectCaption([
        ...mixCutDetailStore.getProjectCaption(),
      ]);
      previewConfing.setCaption(item, true);
      handleSaveMixCutDetailSave();
    }
    if (indexAndType.type === "title") {
      const titles = mixCutDetailStore.getProjectTitle();
      const item = titles[indexAndType.groupIndex].content[indexAndType.index];
      if (
        item.text_setting.x === config?.X &&
        item.text_setting.y === config?.Y
      ) {
        return;
      }
      runInAction(() => {
        item.text_setting = {
          ...item.text_setting,
          x: config?.X || 0,
          y: config?.Y || 0,
          font_size: config?.FontSize + 12,
        };
        titles[indexAndType.groupIndex].content[indexAndType.index] = item;
        mixCutDetailStore.setProTitle([...titles]);
      });
      previewConfing.setTitle(titles[indexAndType.groupIndex], true);
      handleSaveMixCutDetailSave();
    }
  };

  const handleToChangeVideoIndexSetTitleAndCaption = (
    videoGroupIndex: number,
  ) => {
    if (mixCutDetailStore.getProjectIsGlobale()) return;
    previewConfing.setCaption(
      ((mixCutDetailStore.getProjectCaption() || [])[videoGroupIndex] || [])[0],
    );
    previewConfing.setTitle(
      (mixCutDetailStore.getProjectTitle() || [])[videoGroupIndex],
    );
    // if (configIndex === 1) {
    //   previewConfing.setClipId(
    //     mixCutDetailStore.getProjectCaption()[videoGroupIndex][0].id,
    //   );
    // }
    // if (configIndex === 2) {
    //   previewConfing.setClipId(
    //     mixCutDetailStore.getProjectTitle()[videoGroupIndex].id,
    //   );
    // }
  };

  const handleCaptionsBtnClick = (videoGroup: number) => {
    if (!mixCutDetailStore.getProjectIsGlobale()) {
      const caption = mixCutDetailStore.getProjectCaption()[videoGroup][0];
      const title = mixCutDetailStore.getProjectTitle()[videoGroup];
      previewConfing.setCaption(caption);
      previewConfing.setTitle(title);
      // previewConfing.setClipId(null);
    }
  };

  const handleTitleBtnClick = (videoGroup: number) => {
    if (!mixCutDetailStore.getProjectIsGlobale()) {
      const caption = mixCutDetailStore.getProjectCaption()[videoGroup][0];
      const title = mixCutDetailStore.getProjectTitle()[videoGroup];
      previewConfing.setCaption(caption);
      previewConfing.setTitle(title);
      // previewConfing.setClipId(title.content[0].id);
    }
  };

  const handleVideoHeaderBtnClick = (videoGroup: number) => {
    if (!mixCutDetailStore.getProjectIsGlobale()) {
      const caption = mixCutDetailStore.getProjectCaption()[videoGroup][0];
      const title = mixCutDetailStore.getProjectTitle()[videoGroup];
      previewConfing.setCaption(caption);
      previewConfing.setTitle(title);
      console.log("header-btn-click", toJS(title));
      // previewConfing.setClipId(null);
    }
  };

  return {
    toPreviewGetTimeLine,
    getPreviewData,
    findMaterialSetPreview,
    clipId: clipIdStore.getClipId(),
    previewMaterial: previewConfing.getMaterial(),
    previewCaption: previewConfing.getCaption(),
    background: previewConfing.getBackground(),
    previewTitle: previewConfing.getTitle(),
    handleClipIdChange,
    handlePreviewCaptionChange,
    handlePreviewTitleChange,
    handlePreviewBackgroundChange,
    toInitRenderDataSetPreviewData,
    handlePreviewMaterialChange,
    toIdAndConfigSetSetting,
    handleClear,
    handleToChangeVideoIndexSetTitleAndCaption,
    handleCaptionsBtnClick,
    handleTitleBtnClick,
    handleVideoHeaderBtnClick,
    updatePreviewCaptionAndTitle,
    handlePreviewStore,
  };
};

export default usePreviewConfig;
