import { makeAutoObservable, toJS } from "mobx";
import axios, { AxiosProgressEvent, CancelTokenSource } from "axios";
import { v4 as uuid } from "uuid";
import musicPictrue from "@/assets/create-space/upload-music.png";
import {
  IAliOssToken,
  IAliyunRequest,
  getUploadToken,
} from "@/services/fileUpload/fileUpload";
import http from "@/utils/http";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import {
  IMaterialDetail,
  getSimpleMedia,
} from "@/services/createSpace/graphQ/getSimpleMedia";
import {
  fileToUrl,
  getFileType,
} from "@/components/business/MaterialFileModal/UploadFileNode/useParseFile";
import parseMovieGetCoverAndTime from "@/components/business/MaterialFileModal/UploadFileNode/parseMovieGetCoverAndTime";
import getMp3Duration from "@/components/business/MaterialFileModal/UploadFileNode/getMp3Duration";
import { UploadNodeState } from "@/components/business/MaterialFileModal/UploadFileNode/UploadFileNode";
import { makePersistable } from "mobx-persist-store";

const getMaterialState = async (id: string) => {
  const result = await http.get(`/api/v1/space/material/${id}`);
  return (result as any).data.material;
};

const handleUploadFile = async (
  file: File,
  data: IAliyunRequest,
  onProgress: (progress: number) => void,
  cancelToken: CancelTokenSource,
  id: string,
) => {
  const aliyunToken = await getUploadToken({
    filename: data.filename,
    floderId: data.floderId,
    projectId: data.projectId,
    size: file.size,
  });
  return uploadFile(aliyunToken, file, onProgress, cancelToken, id);
};

const uploadFile = async (
  token: IAliOssToken,
  file: File,
  onProgress: (progress: number) => void,
  cancelToken: CancelTokenSource,
  id: string,
): Promise<void> => {
  const newFormData = new FormData();
  newFormData.append("key", `${token.dir}${uuid()}-${file.name}`);
  newFormData.append("callback", token.callback);
  newFormData.append("OSSAccessKeyId", token.accessid);
  newFormData.append("policy", token.policy);
  newFormData.append("Signature", token.signature);
  newFormData.append("expire", token.expire);
  newFormData.append("success_action_status", "200");
  newFormData.append("Content-Disposition", `attachment`);
  newFormData.append("file", file);
  try {
    const response = await axios.post(token.host, newFormData, {
      timeout: 100000000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event: AxiosProgressEvent) => {
        if (event.total) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      },
      cancelToken: cancelToken.token,
    });
    if (response.status === 200) {
      if (response.data.data.method !== "media") {
        return;
      }
      const material_id = response.data.data.id;
      const timer = setInterval(async () => {
        const parseData = await getMaterialState(material_id);
        const status = parseData?.status || 0;
        const task = fileUploadStore.getTaskById(id);
        if (status === 2) {
          task.status = "parseLoading";
        }
        if (status === 3) {
          clearInterval(timer);
          task.status = "parseError";
        }
        if (status === 4) {
          if (task) {
            task.material = parseData;
            try {
              task.detailMaterial = await getSimpleMedia(parseData.id);
              task.img = task.detailMaterial.play_info.cover_url;
            } catch (e) {
              console.log(e);
            } finally {
              task.status = "success";
            }
          }
          clearInterval(timer);
        }
      }, 3000);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Upload canceled");
    } else {
      console.error("Upload failed:", error);
    }
  }
};

export interface UploadGroupItem {
  id: string;
  error: string;
  name: string;
  file: File;
  folder_id: number;
  project_id: number;
  status: UploadNodeState;
  progress: number;
  cancel: CancelTokenSource;
  type?: string;
  img?: string;
  duration?: number;
  material?: any;
  detailMaterial?: IMaterialDetail;
  size: number;
}

export interface UploadGroup {
  id: string;
  update_at: number;
  taskId: string;
  taskList: UploadGroupItem[];
}

class FileUploadStore {
  uploadTasks: Array<UploadGroup> = [];
  queue: string[] = [];
  maxConcurrentUploads = 3; // 最大并发上传数
  activeUploads = 0; // 当前正在上传的任务数

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "uploadTasks",
      properties: ["uploadTasks"],
      storage: window.localStorage,
    });
  }

  // 启动多个上传任务
  processQueue() {
    // 只要队列有任务并且有空余的并发上传位置，就启动上传任务
    while (
      this.activeUploads < this.maxConcurrentUploads &&
      this.queue.length > 0
    ) {
      const taskId = this.queue.shift();
      if (taskId) {
        this.startUpload(taskId); // 启动上传任务
      }
    }
  }

  // 启动单个上传任务
  async startUpload(taskId: string) {
    const task = this.getTaskById(taskId);
    if (!task) return;

    task.status = "uploading"; // 更新任务状态为上传中
    this.activeUploads++; // 增加并发上传数

    try {
      // 上传文件
      await handleUploadFile(
        task.file,
        {
          filename: task.file.name,
          floderId: task.folder_id,
          projectId: task.project_id,
          size: task.size,
        },
        (progress) => {
          this.updateTaskProgress(taskId, Number(progress.toFixed(1))); // 更新进度
        },
        task.cancel,
        task.id,
      );
    } catch (error) {
      task.status = "error";
      task.error = error;
    } finally {
      this.activeUploads--; // 上传完成，减少并发上传数
      this.processQueue(); // 处理队列中的下一任务
    }
  }

  // 获取任务 by ID
  getTaskById(taskId: string) {
    for (const batch of this.uploadTasks) {
      const task = batch.taskList.find((t) => t.id === taskId);
      if (task) {
        return task;
      }
    }
    return null;
  }

  // 更新任务的上传进度
  updateTaskProgress(taskId: string, progress: number) {
    const task = this.getTaskById(taskId);
    if (task) {
      task.progress = progress;
    }
  }

  // 取消上传任务
  cancelUploadTask(taskId: string) {
    for (const batch of this.uploadTasks) {
      const taskIndex = batch.taskList.findIndex((t) => t.id === taskId);
      if (taskIndex !== -1) {
        const task = batch.taskList[taskIndex];
        if (task.cancel) {
          task.cancel.cancel(); // 取消上传任务
        }
        batch.taskList.splice(taskIndex, 1);
        if (batch.taskList.length === 0) {
          const batchIndex = this.uploadTasks.findIndex(
            (b) => b.id === batch.id,
          );
          if (batchIndex !== -1) {
            this.uploadTasks.splice(batchIndex, 1); // 删除没有任务的上传组
          }
        }
        break;
      }
    }
  }
  // 清除未上传完的数据
  clearUploadTask() {
    this.uploadTasks = this.uploadTasks
      .filter((v) => v.taskList)
      .filter((subArray: any) =>
        subArray.taskList.every((item) => item.status !== "uploading"),
      );
  }

  // 删除单条数据
  deleteUploadTask(id) {
    const nArr = [...this.uploadTasks];
    const index = nArr.findIndex((item) => item.id === id);
    if (index > -1) {
      nArr.splice(index, 1);
      this.uploadTasks = nArr;
    }
  }
  // 添加上传任务
  addUploadTasks(
    files: File[],
    folder_id: number,
    project_id: number,
    groupName: string,
    batchId: string,
    taskId: string,
  ) {
    const tasks = files.map((file) => {
      const taskId = uuid();
      const source = axios.CancelToken.source();
      return this.parseFile(file).then(({ fileType, img, duration }) => ({
        name: file.name,
        id: taskId,
        file,
        folder_id,
        project_id,
        status: "queued" as UploadNodeState,
        progress: 0,
        cancel: source,
        type: fileType,
        img,
        duration,
        error: "",
        size: file.size,
      }));
    });

    Promise.all(tasks).then((resolvedTasks) => {
      this.uploadTasks.push({
        id: batchId,
        taskId: taskId,
        taskList: resolvedTasks,
        update_at: Date.now(),
      });
      resolvedTasks.forEach((task) => {
        this.queue.push(task.id);
      });
      // 立即处理上传队列
      this.processQueue();
    });
  }

  // 解析文件类型并返回相关信息
  async parseFile(file: File) {
    const fileType = getFileType(file);
    let img = "";
    let duration = 0;

    switch (fileType) {
      case CardTypeEnum.MOVIE: {
        try {
          const { img: movieImg, duration: movieDuration } =
            await parseMovieGetCoverAndTime(file);
          img = movieImg;
          duration = movieDuration;
        } catch (e) {
          console.log(e);
        }
        break;
      }
      case CardTypeEnum.MUSIC: {
        duration = await getMp3Duration(file);
        img = musicPictrue;
        break;
      }
      case CardTypeEnum.PICTURE: {
        img = fileToUrl(file);
        break;
      }
      default:
        break;
    }

    return { fileType, img, duration };
  }

  // 清空审核状态
  clearExamine = () => {
    const newTasks = [...this.uploadTasks];
    this.uploadTasks = newTasks.map((batch) => ({
      ...batch,
      taskList: batch.taskList.map((task) => ({
        ...task,
        isExamine: true,
      })),
    }));
  };
}

export const fileUploadStore = new FileUploadStore();
