import { makeAutoObservable, action, toJS } from "mobx";
import { compare, applyPatch } from "fast-json-patch";
class CreationScriptStores {
  dataSource = [];

  constructor() {
    makeAutoObservable(this);
  }

  updateData = action((index, type, value) => {
    const newData = [...this.dataSource];
    newData[index][type] = value;
    this.dataSource = newData;
  });
  addData = action((value) => {
    this.dataSource.push(value);
  });
  editCaption = action((index, type, values) => {
    const { value, spliceIndex, textIndex } = values;
    const newData = [...this.dataSource];
    if (type == "push") {
      newData[index]["caption"].push(value);
    } else if (type == "splice") {
      newData[index]["caption"].splice(spliceIndex, 1);
    } else if (type == "setText") {
      newData[index]["caption"][textIndex] = value;
    }
    this.dataSource = newData;
  });
  changeCaption = action((index, type, value) => {
    const newData = [...this.dataSource];

    newData[index][type] = value;
    this.dataSource = newData;
  });
  delData = action((newData) => {
    this.dataSource = JSON.parse(JSON.stringify(newData));
  });
  compareUpdate = action((newData) => {
    // 计算旧数据和新数据之间的差异
    const patch = compare(this.dataSource, newData);
    // 应用差异到旧数据上
    applyPatch(this.dataSource, patch);
  });
}

const creationStores = new CreationScriptStores();
export default creationStores;
