import { IOrigin } from "@/stores/mixCutDetailStore/type";
import useMaterialPlayInfo from "./useMaterialPlayInfo";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailInit from "./useMixCutDetailInit";
import usePreviewConfig from "../preview/usePreview";
import usePreviewInfo from "./usePreviewInfo";
import { v4 as uuid } from "uuid";
import { duration } from "moment";
import useMixCutDetailSave from "./useMixCutDetailSave";
// import previewConfing from "@/stores/previewStore";

export interface IUseJsonToRenderObj {
  handletoOriginJsonRender: (data: IOrigin) => void;
}

const useJsonToRenderObj = (): IUseJsonToRenderObj => {
  const { toInitRenderDataSetPreviewData } = usePreviewConfig();
  const { initTransitionGroup, initGlobleCaptionGroup } = useMixCutDetailInit();
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { toIdsSetPlayInfo } = useMaterialPlayInfo();
  const { calcPreviewInfo } = usePreviewInfo();
  const handletoOriginJsonRender = (data: IOrigin) => {
    mixCutDetailStore.setProjectMaterialsInfo(data._materials);
    mixCutDetailStore.setVersion(data.version || null);
    const newMaterialInfoKeys = Object.keys(data._materials || {});
    toIdsSetPlayInfo(newMaterialInfoKeys);
    mixCutDetailStore.setProjectSences(
      data.scenes.map((item) => {
        return {
          id: item.id || uuid(),
          name: item.name,
          setting: item.setting,
          materials: item.materials,
        };
      }),
    );
    if (data?.global_settings?.enabled) {
      mixCutDetailStore.setProTitle(data?.global_settings?.titles);
    } else {
      const titles = data?.scenes.map((item) => item.title);
      mixCutDetailStore.setProTitle(titles);
    }
    mixCutDetailStore.setProjectCover(
      (data?.global_settings?.cover?.images || []).map((item) => item),
    );
    mixCutDetailStore.setProjectBgAudio(
      data?.global_settings?.background?.audio,
    );
    mixCutDetailStore.setProjectBgColor(
      data?.global_settings?.background?.color,
    );
    mixCutDetailStore.setProjectBgImages(
      data?.global_settings?.background?.images,
    );
    mixCutDetailStore.setProjectBgAudioVolum(
      data?.global_settings?.background?.audio_volume || 50,
    );
    if (data.global_settings.enabled) {
      initTransitionGroup();
      mixCutDetailStore.setProjectIsGolbal(true);
      mixCutDetailStore.setProjectGlobalCaptions(data.global_settings.captions);
      mixCutDetailStore.setProjectGlobalTransition({
        duration: data.global_settings.transition.duration,
        effects: data.global_settings.transition.effects,
      });
    } else {
      initGlobleCaptionGroup();
      mixCutDetailStore.setProjectIsGolbal(false);
      mixCutDetailStore.setProjectCaption(
        data.scenes.map((item) => {
          return item.caption;
        }),
      );
      mixCutDetailStore.setTransition(data.transition);
    }
    if (!mixCutDetailStore.getVerison()) {
      initFontBgColor();
      handleSaveMixCutDetailSave();
    }
    toInitRenderDataSetPreviewData(data);
    calcPreviewInfo(data);
  };

  const initFontBgColor = () => {
    const captions = mixCutDetailStore.getProjectCaption();
    captions.forEach((item) => {
      item.forEach((caption) => {
        const bgColor = caption.text_setting.background_color;
        if (bgColor === "#000000" || bgColor === "black") {
          caption.text_setting.background_color = "";
        }
      });
    });
    const titles = mixCutDetailStore.getProjectTitle();
    titles.forEach((item) => {
      item.content.forEach((title) => {
        const bgColor = title.text_setting.background_color;
        if (bgColor === "#000000" || bgColor === "black") {
          title.text_setting.background_color = "";
        }
      });
    });
    const gCaptions = mixCutDetailStore.getProjectGlobalCaptions();
    gCaptions.forEach((gCaption) => {
      const bgColor = gCaption.text_setting.background_color;
      if (bgColor === "#000000" || bgColor === "black") {
        gCaption.text_setting.background_color = "";
      }
    });
    mixCutDetailStore.setVersion("1.0.0");
  };

  return {
    handletoOriginJsonRender,
  };
};

export default useJsonToRenderObj;
