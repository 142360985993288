import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  getInitGlobalCaption,
  getToSencesInitTransitionItem,
  initCaptionItem,
  initISencesItem,
  toIndexGetInitTitleTextSetting,
} from "@/stores/mixCutDetailStore/init";
import { v4 as uuid } from "uuid";

export interface IUseMixCutDetailInitReturn {
  initVersion: () => void;
  initAllMixCutDetal: () => void;
  initVideoGroup: () => void;
  initCaptionGroup: () => void;
  initTitleGroup: () => void;
  initTransitionGroup: () => void;
  initBg: () => void;
  initGlobleCaptionGroup: () => void;
}

const useMixCutDetailInit = () => {
  const initAllMixCutDetal = () => {
    initVersion();
    initCaptionGroup();
    initVideoGroup();
    initTitleGroup();
    initTransitionGroup();
    initBg();
    initGlobleCaptionGroup();
  };

  const initVersion = () => {
    mixCutDetailStore.setVersion("1.0.0");
  };

  const initVideoGroup = () => {
    mixCutDetailStore.setProjectSences([{ ...initISencesItem, id: uuid() }]);
  };
  const initCaptionGroup = () => {
    mixCutDetailStore.setProjectCaption([
      [
        {
          id: Math.floor(Date.now() + Math.random() * 100000),
          ...initCaptionItem,
        },
      ],
    ]);
  };
  const initTitleGroup = () => {
    mixCutDetailStore.setProTitle([
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
          },
        ],
      },
    ]);
  };
  const initTransitionGroup = () => {
    mixCutDetailStore.setTransition({ ...getToSencesInitTransitionItem(0) });
  };
  const initBg = () => {
    mixCutDetailStore.setProjectBgColor("");
    mixCutDetailStore.setProjectCover([]);
    mixCutDetailStore.setProjectBgAudio([]);
    mixCutDetailStore.setProjectBgImages([]);
  };
  const initGlobleCaptionGroup = () => {
    mixCutDetailStore.setProjectGlobalCaptions([...getInitGlobalCaption()]);
  };

  return {
    initAllMixCutDetal,
    initVideoGroup,
    initCaptionGroup,
    initTitleGroup,
    initTransitionGroup,
    initBg,
    initGlobleCaptionGroup,
    initVersion,
  };
};

export default useMixCutDetailInit;
