import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetail from "./useMixCutDetail";
import { saveEngineeringContent } from "@/services/mixCutDetail/mixCutDetail";
import moment from "moment";
import usePreviewInfo from "./usePreviewInfo";
import { Message } from "@arco-design/web-react";

interface IMixCutDetailSavaReturn {
  handleSaveMixCutDetailSave: () => void;
  quickSaveMixCutDetail: () => void;
}

let debounceTimeout: NodeJS.Timeout | null = null;

const useMixCutDetailSave = (): IMixCutDetailSavaReturn => {
  const { handleGetOriginJson } = useMixCutDetail();
  const { calcPreviewInfo } = usePreviewInfo();

  const quickSaveMixCutDetail = async () => {
    const bigObj = handleGetOriginJson();
    const content = JSON.stringify(bigObj);
    const mixCutDetailMsg = { ...mixCutDetailStore.getProjectMsg() };
    await saveEngineeringContent(mixCutDetailMsg.id as any, content);
    calcPreviewInfo(bigObj);
  };

  const handleSaveMixCutDetailSave = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(async () => {
      const mixCutDetailMsg = { ...mixCutDetailStore.getProjectMsg() };
      const bigObj = handleGetOriginJson();
      const content = JSON.stringify(bigObj);
      try {
        await saveEngineeringContent(mixCutDetailMsg.id as any, content);
      } catch (e) {
        if (e === "资源不存在") {
          Message.error("工程已被删除！");
          setTimeout(() => {
            window.close();
          }, 3000);
          return;
        }
        Message.error(e);
        console.log(e);
      }
      mixCutDetailMsg.updated_at = moment(Date.now()).format(
        "YYYY-MM-DD HH:mm",
      ) as any;
      mixCutDetailStore.setProjectMsg({ ...mixCutDetailMsg });
      calcPreviewInfo(bigObj);
    }, 1000);
  };

  return {
    handleSaveMixCutDetailSave,
    quickSaveMixCutDetail,
  };
};

export default useMixCutDetailSave;
