import http from "@/utils/http";

// 合成视频列表

export async function getSyntheticVideoList(params): Promise<any> {
  const items = Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) => value !== "" && value !== null && value !== undefined,
    ),
  );
  delete items.status;
  delete items.style;
  const query = `  
  {
  composed_list(${Object.entries(items)
    .map(
      ([key, value]) =>
        `${key}: ${typeof value != "number" ? `"${value}"` : value}`,
    )
    .join(", ")},) {
    sum
     statistics{
            total
            success
            failed
            progress
            status
            title
            owner_uuid
            owner_name
            updated_at 
            project_id
            folder_id
        }
  }
}
  `;

  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res.data.composed_list;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getSyntheticSubInfo(params): Promise<any> {
  const query = `  
  {
  compose_sub_task(task_nos:${params}) {
    sum
    items {
      task_no
      status
      title
      timeline_id
      project_id
      folder_id
      creator_uuid
      creator
      created_at
    }
  }
}
  `;
  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res.data.compose_sub_task;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 上传中心素材列表
export async function geTmaterialTask(params): Promise<any> {
  const items = Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) =>
        value !== "" && value !== null && value !== undefined && value != 0,
    ),
  );
  delete items.status;
  delete items.style;
  const query = `  
  {
  upload_material_list(${Object.entries(items)
    .map(
      ([key, value]) =>
        `${key}: ${typeof value != "number" ? `"${value}"` : value}`,
    )
    .join(", ")},) {
    next_cursor
    items {
            id
            project_id
            parent_id
            level
            search_path
            name
            type
            team_id
            file_size
            created_at
            updated_at
            deleted_at
            creator
        }
  }
}
  `;

  try {
    const res = await http.post(`/api/v1/graphql/`, {
      query,
    });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

//   素材所在位置
export async function geMaterialTask(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/space/material/task/", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getVideoListSession(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/space/session/", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getVideoTaskProgress(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/space/session/task/progress", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
