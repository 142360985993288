import http from "@/utils/http";
import { clearEmpty } from "../common";
import MaterialCard, {
  CardTypeEnum,
} from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import { ProjectNavTypeEnum } from "@/stores/spaceStore/projectStore";
import { ProjectTypeEnum } from "@/pages/CreationSpace/components/ProjectCard/ProjectCard";
import { IMaterialDetail } from "./graphQ/getSimpleMedia";

export interface ICreateFolderProps {
  path: string;
  projectId: number;
}

export interface MaterialRequest {
  projectId: string | number;
  type?: string | number;
  parentId: string | number;
  // sortField?: string;
  sortSc?: string;
  keyword?: string;
  page?: number;
  pageSize?: number;
}

export interface CreateFolderResponse {
  id: string;
  updateTime: string;
}
// 创建文件夹
export const createFolder = async (
  data: ICreateFolderProps,
): Promise<CreateFolderResponse> => {
  const result = await http.post(`/api/v1/space/material`, {
    path: data.path,
    project_id: data.projectId,
  });
  return {
    id: result.data.folder_id,
    updateTime: result.data.updated_at,
  };
};

// export interface IGetMaterialDataResponse {
//   created_at: number;
//   id: string;
//   name: string;
//   project_id: string;
//   type: number | string;
//   updated_at: number;
// }

const format = (data: MaterialRequest) => {
  return {
    project_id: data.projectId,
    type: data.type || "",
    parent_id: data.parentId || 0,
    // sort_field: data.sortField || "",
    sort_sc: data.sortSc || "",
    keyword: data.keyword || "",
    page: data.page,
    page_size: data.pageSize,
  };
};

export const toProcessType = (item: IMaterialDetail) => {
  const obj = {
    1: CardTypeEnum.MOVIE,
    2: CardTypeEnum.MUSIC,
    3: CardTypeEnum.PICTURE,
    4: ProjectTypeEnum.FOLDER,
  };
  return { ...item, type: obj[item?.type] };
};

// 修改资源文件名
export const reNameMaterial = async (name: string, id: string) => {
  const result = await http.put(`/api/v1/space/material/${id}/name`, {
    name,
  });
  return result;
};

//批量删除资源
/**
 * state true 放入回收站 false 彻底删除
 */
export const deleteMaterial = async (
  ids: string[] | string,
  state: boolean,
) => {
  const newIds = Array.isArray(ids)
    ? ids.map((item) => Number(item))
    : [Number(ids)];
  const result = await http.delete(`/api/v1/space/material/batch`, {
    data: {
      ids: newIds,
      is_real: state,
    },
  });
  return result;
};

/**
 * 批量复制素材或者文件夹
 */
export const copyMaterailsOrFolders = async (
  ids: string[] | string,
  project_id: number,
) => {
  const newIds = Array.isArray(ids)
    ? ids.map((item) => Number(item))
    : [Number(ids)];
  const result = await http.post(`/api/v1/space/material/batch/copy`, {
    ids: newIds,
    project_id,
  });
  return result;
};

export interface IMoveRequest {
  ids: number | number[];
  targetParentId: number;
  projectId: number;
}

const idToNumberList = (
  ids: number | string | number[] | string[],
): number[] => {
  if (Array.isArray(ids)) {
    return ids.map((item) => Number(item));
  }
  return [Number(ids)];
};

export const moveFileAndFolder = async (data: IMoveRequest) => {
  const result = await http.post(`/api/v1/space/material/batch/move`, {
    ids: idToNumberList(data.ids),
    target_parent_id: Number(data.targetParentId),
    project_id: Number(data.projectId),
  });
  return result;
};

export const copyToFileAndFolder = async (data: IMoveRequest) => {
  const result = await http.post(`/api/v1/space/material/batch/copy`, {
    ids: idToNumberList(data.ids),
    target_parent_id: Number(data.targetParentId),
    project_id: Number(data.projectId),
  });
  return result;
};

export type SourceType =
  | "music"
  | "cover"
  | "background"
  | "music_sound"
  | "video";

export interface IMaterialLinkEngineering {
  editing_session_id: number[];
  material_id: number;
  source: SourceType;
}

//素材批量关联工程
export const materialLinkEngineering = async (
  data: IMaterialLinkEngineering,
) => {
  const newData = {
    ...data,
    material_id: [data.material_id],
  };
  const result = await http.post(`/api/v1/editing/material/bind`, {
    ...newData,
  });
  return result;
};

export const batch_download = async (data) => {
  const result = await http.post(`/api/v1/space/media/batch_download`, data);
  return result;
};
