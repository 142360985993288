import http from "@/utils/http";
import { getTokenToLocalStorage } from "@/utils/token";
import axios from "axios";
/**
 * 获取团队列表
 * **/
export async function getMyTeamsList(): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/myteams");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * 切换团队
 * **/
export async function getTeamsSwitch(id): Promise<any> {
  try {
    const res = await http.put(`/api/v1/team/${id}/switch`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

// 创建团队
export async function addTeam(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
// 查找团队下所有成员
export async function getTeamAll(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/team/member/search", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
export async function getIsTeam(): Promise<any> {
  try {
    const res = await http.post(
      `/api/v1/graphql/`,
      `{
        team_user_role {
          team {
            id
            name
          }
          roles {
            id
            name
            code
            description
          }
          nickname
          exists
          status
          expired_at
        }
      }`,
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      },
    );
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 退出团队
export async function teamQuit(): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/quit");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

// 删除团队
export async function teamDelete(params): Promise<any> {
  try {
    const res = await http.delete("/api/v1/team/", params);

    if (res) {
      return res;
    } else {
      console.log("接口错误");
      return res;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

// 修改名称
export async function setUserName(params): Promise<any> {
  try {
    const res = await http.put("/api/v1/user/name", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

export async function getUserInfo(): Promise<any> {
  try {
    const res = await http.get("/api/v1/user/info");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getUserContact(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/contact", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getUserAvatar(params): Promise<any> {
  try {
    const res = await http.put("/api/v1/user/avatar", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getUserAvatarUrl(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/space/oss/url?oss_key=${params}`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getCompanyInfo(params): Promise<any> {
  try {
    const res = await http.post(`/api/v1/team/company/info`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getCompanyLicense(params): Promise<any> {
  try {
    const res = await http.post(`/api/v1/team/company/license/ocr`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getPersonalCardocr(params): Promise<any> {
  const res = await http.post(`/api/v1/user/personal/card/face/ocr`, params);
  return res;
}

export async function getPersonalBackocr(params): Promise<any> {
  const res = await http.post(`/api/v1/user/personal/card/back/ocr`, params);
  return res;
}
export async function getPersonal_info(params): Promise<any> {
  try {
    const res = await http.post(`/api/v1/user/personal/info`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getPersonalInfo(): Promise<any> {
  try {
    const res = await http.get(`/api/v1/user/personal/info`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
}
