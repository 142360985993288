import http from "@/utils/http";

export interface IGetPortfolioFolderRequest {
  project_id: number;
  type: number;
  sort_sc?: "desc" | "asc";
  keyword?: string;
  page: number;
  page_size: number;
  parent_id: number;
}

export interface IGetSpaceCompositionFolderRequest {
  project_id: number;
  parent_id: number;
}

//搜索工程
export const getSpaceComposition = async (
  params: IGetPortfolioFolderRequest,
): Promise<{ data: any[]; total: number }> => {
  const result = await http.get(`/api/v1/space/composition`, { params });
  return { data: result.data?.items || [], total: result.data.total };
};

export interface IPortfolioSearchParams {
  project_id: number;
  type?: number;
  parent_id: number;
  sort_field?: string;
  sort_sc?: string;
  page: number;
  page_size: number;
  keyword: string;
}

export interface IPortfolioResponse {
  bitrate: string;
  created_at: number;
  creator_name: string;
  creator_uuid: string;
  download_count: number;
  duration: number;
  file_name: string;
  file_size: number;
  file_type: string;
  format_name: string;
  fps: string;
  height: number;
  id: number;
  level: number;
  media_id: string;
  name: string;
  num_frames: string;
  parent_id: number;
  play_info: {
    cover_url: string;
    play_url: string;
  };
  project_id: number;
  search_path: number;
  status: number;
  team_id: number;
  type: number;
  updated_at: number;
  width: number;
}

export const queryFields: string[] = [
  "id",
  "media_id",
  "status",
  "format_name",
  "file_size",
  "width",
  "height",
  "duration",
  "file_name",
  "fps",
  "num_frames",
  "download_count",
  "creator_uuid",
  "creator_name",
  "file_type",
  "type",
  "created_at",
  "updated_at",
  "team_id",
  "project_id",
  "parent_id",
  "level",
  "search_path",
  "name",
  "bitrate",
  "play_info { play_url ,cover_url}",
];

export const generateGraphQLQuery =
  (data: IPortfolioSearchParams) => (fields: string[]) => {
    console.log(data);
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.project_id !== undefined)
      searchParams.push(`project_id: ${data.project_id}`);
    if (data.parent_id !== undefined)
      searchParams.push(`parent_id: ${data.parent_id}`);
    if (data.type !== 0) searchParams.push(`type: ${data.type}`);
    if (data.page !== undefined) searchParams.push(`page: ${data.page}`);
    if (data.page_size !== undefined)
      searchParams.push(`page_size: ${data.page_size}`);
    if (data.sort_sc != undefined) {
      searchParams.push(`sort_sc:"${data.sort_sc}"`);
    }
    if (data.sort_field != undefined) {
      searchParams.push(`sort_field:"${data.sort_field}"`);
    }
    if (data.keyword !== undefined && data.keyword.length > 0) {
      searchParams.push(`keyword:"${data.keyword}"`);
    }
    const searchStr = searchParams.join(", ");
    return `{
      composition_list( ${searchStr}) {
        items { ${selectedFields} }
        total
      }
    }`;
  };

export const getSpaceCompositionGraphql = async (
  data: IPortfolioSearchParams,
): Promise<{ data: IPortfolioResponse[]; total: number }> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    generateGraphQLQuery(data)(queryFields).toString(),
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return {
    data: result?.data?.composition_list?.items || [],
    total: result?.data?.composition_list?.total || 0,
  };
};

export interface IGetAllPortfolioFolderRequest {
  created_at: number;
  id: number;
  level: number;
  name: string;
  parent_id: number;
  project_id: number;
  team_id: number;
  updated_at: number;
}
export const getAllPortfolioFolder = async (): Promise<
  IGetAllPortfolioFolderRequest[]
> => {
  const result = await http.get(`/api/v1/space/composition/all`);
  return result.data.items;
};

// 删除文件夹
export const portfolioBatchDelete = async (id: number[]) => {
  const result = await http.delete(`/api/v1/space/composition/batch`, {
    data: {
      is_real: true,
      ids: id,
    },
  });
  return result.data;
};
// 放入回收站
export const portfolioBatchHidden = async (id: number[]) => {
  const result = await http.delete(`/api/v1/space/composition/batch`, {
    data: {
      is_real: false,
      ids: id,
    },
  });
  return result.data;
};

export interface ICopyAndMoveRequest {
  ids: number[];
  target_parent_id: number;
  project_id: number;
}

// 复制文件夹
export const copyPortfolioFolder = async (data: ICopyAndMoveRequest) => {
  const result = await http.post(`/api/v1/space/composition/batch/copy`, {
    ...data,
  });
  return result;
};

// 移动文件夹
export const movePortfolioFolder = async (data: ICopyAndMoveRequest) => {
  const result = await http.post(`/api/v1/space/composition/batch/move`, {
    ...data,
  });
  return result.data;
};

// 查询所有文件夹
export const getSpaceCompositionFolder = async (
  params: IGetSpaceCompositionFolderRequest,
): Promise<{ data: any[]; total: number }> => {
  const result = await http.get(`/api/v1/space/composition`, {
    params: { ...params, page: 1, page_size: 1000000, type: 4 },
  });
  return result.data.items;
};

export interface ICreatePortfolioFolderRequest {
  path: string;
  project_id: number;
}

// 新建成片文件夹
export const createPortfolioFolder = async (
  data: ICreatePortfolioFolderRequest,
) => {
  const result = await http.post(`/api/v1/space/composition`, {
    ...data,
  });
  return result;
};

// 修改名称
export const portfolioRename = async (id: number, name: string) => {
  return await http.put(`/api/v1/space/composition/${id}`, {
    name,
  });
};
