import { makeAutoObservable } from "mobx";
import { Message } from "@arco-design/web-react";
import {
  reset_password,
  exist_phone_number,
  register,
  user_login,
  send_code,
  verify_code,
  login_sms,
  team_addop,
} from "@/services/login";
import { setTokenToLocalStorage } from "@/utils/token";
import { makePersistable } from "mobx-persist-store";
interface IUserInfo {
  avatar_color: string;
  created_at: number;
  last_login: number;
  last_login_ip: string;
  nickname: string;
  phone_number: string;
  updated_at: number;
  uuid: string;
  password_strength: number;
}
class Login_to {
  token = null;
  user_info: IUserInfo;

  request_id = {};
  uuid = {};
  isSuccess = false;
  isLoading = false;
  team_id = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "LoginStore",
      properties: ["uuid", "user_info"],
      storage: window.localStorage,
    });
  }
  /***
   * 修改状态
   */
  someMethod(data) {
    const { token, user_info, request_id } = data;
    this.token = token;
    this.user_info = user_info;
    this.request_id = request_id;
    this.uuid = user_info.uuid;
    // your code here
  }
  /***
   *  当temid 为0的 时候 调用此接口
   */
  team_myteams_gettemid_to(data) {
    try {
      return team_addop(data).then((res) => {
        console.log(res);
        if (res?.code == 0 && res?.message == "OK") {
          setTokenToLocalStorage(res.data.token);
          Message.success("创建团队成功");
          return true;
        } else {
          Message.info(res);
          return false;
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }

  /***
   * 登陆
   */
  Accc_use_login(datalist) {
    try {
      user_login(datalist).then((res) => {
        console.log(res);

        if (res?.code == 0 && res?.message == "OK") {
          this.someMethod(res.data);
          setTokenToLocalStorage(res.data.token);
          this.isSuccess = true;
          this.team_id = res.data.team_id;
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }
  /***
   * 短信注册/登陆
   */
  smslong_login_sms(datalist) {
    try {
      login_sms({
        phone_number: datalist.phone_number,
        verify_code: datalist.code,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          this.isSuccess = true;
          this.team_id = res.data.team_id;
          this.someMethod(res.data);
          setTokenToLocalStorage(res.data.token);
          Message.success("短信登录/注册成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
    }
    return false;
  }
  /***
   * 注册成功
   */
  register_to(datalist) {
    try {
      register({
        phone_number: datalist.phone_number,
        verify_code: datalist.code,
        password: datalist.password,
        repeat_password: datalist.popp,
        password_strength: datalist.nub == 0 ? 1 : datalist.nub,
      }).then((res) => {
        if (res?.code == 0) {
          this.isSuccess = true;
          this.someMethod(res.data);
          setTokenToLocalStorage(res.data.token);
          Message.success("注册成功");
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 忘记密码
   */
  reset_password_to(datalist) {
    try {
      return reset_password({
        phone_number: datalist.phone_number,
        verify_code: datalist.code,
        new_password: datalist.password,
        repeat_password: datalist.popp,
        password_strength: datalist.nub == 0 ? 1 : datalist.nub,
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          return "OK";
        } else {
          Message.info(res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 发送短信
   */
  async sendCode_to(formrefSmlong, reset_password = false) {
    try {
      await send_code({
        phone_number: formrefSmlong.current.getFieldValue("phone_number"),
        method: reset_password ? "reset_password" : "register",
        country_code: "+86",
      });
      const last_phnumber = formrefSmlong.current
        .getFieldValue("phone_number")
        .slice(-4);
      Message.success(`验证码短信已经发送至手机尾号${last_phnumber}，请查收`);
    } catch (error) {
      Message.info(error);
    }
  }
  /***
   * 校验短信
   */
  async verifyCode_to(formrefSmlong, reset_password = false) {
    //
    try {
      return await verify_code({
        phone_number: formrefSmlong.current.getFieldValue("phone_number"),
        method: reset_password ? "reset_password" : "register",
        verify_code: formrefSmlong.current.getFieldValue("code"),
        country_code: "+86",
      }).then((res) => {
        if (res?.code == 0 && res?.message == "OK") {
          return "ok";
        } else {
          return "no";
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /***
   * 判断手机号是否存在
   */
  async exist_phone_number_to(FormreGist) {
    try {
      return await exist_phone_number({
        phone_number: FormreGist.current.getFieldValue("phone_number"),
      }).then((res) => {
        if (res?.code == 0 && res?.data == true) {
          return "ok";
        } else if (res.code == 0 && res.data == false) {
          return "no";
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}

const Login = new Login_to();
export default Login;
