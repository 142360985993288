import http from "@/utils/http";
import { Message } from "@arco-design/web-react";
import { toProcessType } from "../material";
import { IMaterialDetail } from "./getSimpleMedia";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import { ProjectTypeEnum } from "@/pages/CreationSpace/components/ProjectCard/ProjectCard";

export interface IGetMaterialSearch {
  projectId: number;
  parentId: number;
  type: number[];
  page: number;
  page_size: number;
  sortSc?: string;
  keyword: string;
  sort_field: string;
}

export const generateGraphQLQuery =
  (data: IGetMaterialSearch) =>
  (fields: string[]): string => {
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.projectId !== undefined)
      searchParams.push(`project_id: ${data.projectId}`);
    if (data.parentId !== undefined)
      searchParams.push(`parent_id: ${data.parentId}`);
    if (data.type !== undefined) searchParams.push(`type: [${data.type}]`);
    if (data.page !== undefined) searchParams.push(`page: ${data.page}`);
    if (data.page_size !== undefined)
      searchParams.push(`page_size: ${data.page_size}`);
    if (data.sortSc != undefined) {
      searchParams.push(`sort_sc:"${data.sortSc}"`);
    }
    if (data.sort_field != undefined) {
      searchParams.push(`sort_field:"${data.sort_field}"`);
    }
    if (data.keyword !== undefined && data.keyword.length > 0) {
      searchParams.push(`keyword:"${data.keyword}"`);
    }

    const searchStr = searchParams.join(", ");
    return `{
      material_list( ${searchStr}) {
        items { ${selectedFields} }
        total
      }
    }`;
  };

export const queryFields: string[] = [
  "id",
  "project_id",
  "level",
  "search_path",
  "name",
  "media_id",
  "type",
  "file_type",
  "file_size",
  "format_name",
  "bitrate",
  "width",
  "height",
  "duration",
  "source",
  "status",
  "created_at",
  "updated_at",
  "parent_id",
  "fps",
  "num_frames",
  "file_name",
  "play_info {  play_url  cover_url }",
  "use_by_session",
  "compose_count",
  "creator",
];

export const queryRecycleQueryFields: string[] = [
  "id",
  "name",
  "type",
  "keep_days",
  "source",
  "duration",
  "cover_url",
  "created_at",
  "duration",
  "updated_at",
  "source_id",
];

export const getMetarialData = async (
  data: IGetMaterialSearch,
): Promise<{
  data: IMaterialDetail[];
  total: number;
}> => {
  try {
    const result = await http.post(
      `/api/v1/graphql/`,
      generateGraphQLQuery(data)(queryFields).toString(),
      {
        headers: {
          "Content-Type": "application/graphql",
        },
      },
    );
    return {
      data: (result as any).data.material_list.items.map((item) => {
        return toProcessType(item);
      }),
      total: result.data.material_list.total,
    };
  } catch (e) {
    console.log(e);
    Message.error("获取graph接口失败");
  }
};

export const generateRecycleGraphQLQuery =
  (data: { keyword: string; page: number; page_size: number }) =>
  (fields: string[]): string => {
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.keyword !== "" && data.keyword) {
      searchParams.push(`keyword:"${data?.keyword || ""}"`);
    }
    if (data.page) {
      searchParams.push(`page:${data?.page || ""}`);
    }
    if (data.page_size) {
      searchParams.push(`page_size:${data?.page_size || ""}`);
    }
    const searchStr = searchParams.join(", ");
    return `{
      material_recycle( ${searchStr}) {
        items { ${selectedFields} }
        total
      }
    }`;
  };

export interface IRecycleBinDataResponse {
  cover_url: string;
  created_at: number;
  id: number;
  keep_days: string;
  duration: number;
  name: string;
  source: number;
  type: CardTypeEnum | ProjectTypeEnum;
  updated_at: number;
  source_id: number;
}

export const getRecycleBinData = async (data: {
  keyword: string;
  page: number;
  page_size: number;
}): Promise<{
  data: IRecycleBinDataResponse[];
  total: number;
}> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    generateRecycleGraphQLQuery(data)(queryRecycleQueryFields).toString(),
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return {
    data: result.data.material_recycle.items.map((item) => {
      return toProcessType(item);
    }),
    total: result.data.material_recycle.total,
  };
};

export interface IGetMaterialUploadProps {
  page: number;
  page_size: number;
  projectId: number;
  parentId: number;
  type: number[];
}

export const generateUploadGraphQLQuery =
  (data: IGetMaterialUploadProps) =>
  (fields: string[]): string => {
    const selectedFields = fields.join("\n");
    const searchParams: string[] = [];
    if (data.projectId !== undefined)
      searchParams.push(`project_id: ${data.projectId}`);
    if (data.parentId !== undefined)
      searchParams.push(`parent_id: ${data.parentId}`);
    if (data.type !== undefined) {
      let type = `type: [`;
      data.type.forEach((item, index) => {
        if (index < data.type.length - 1) {
          type += item + ",";
        } else {
          type += item;
        }
      });
      type += "]";
      searchParams.push(type);
    }
    if (data.page !== undefined) searchParams.push(`page: ${data.page}`);
    if (data.page_size !== undefined)
      searchParams.push(`page_size: ${data.page_size}`);
    const searchStr = searchParams.join(", ");
    return `{
      material_list( ${searchStr}) {
        items { ${selectedFields} }
        total
      }
    }`;
  };

// export interface IUploadMaterialMsg {
//   bitrate: string;
//   created_at: number;
//   duration: number;
//   file_name: string;
//   file_size: number;
//   file_type: string;
//   format_name: string;
//   fps: string;
//   height: number;
//   id: string;
//   level: number;
//   media_id: string;
//   name: string;
//   num_frames: string;
//   parent_id: string;
//   play_info: {
//     cover_url: string;
//     play_url: string;
//   };
//   project_id: string;
//   search_path: string;
//   source: string;
//   status: string;
//   team_id: string;
//   type: CardTypeEnum;
//   updated_at: number;
//   width: number;
// }

export const getMaterialUploadData = async (
  data: IGetMaterialUploadProps,
): Promise<{
  data: IMaterialDetail[];
  total: number;
}> => {
  const result = await http.post(
    `/api/v1/graphql/`,
    generateUploadGraphQLQuery(data)(queryFields).toString(),
    {
      headers: {
        "Content-Type": "application/graphql",
      },
    },
  );
  return {
    data: (result as any).data.material_list.items.map((item) => {
      return {
        ...toProcessType(item),
      };
    }),
    total: result.data.material_list.total,
  };
};
