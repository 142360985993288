/* eslint-disable no-constant-condition */
import http from "@/utils/http";
import { getTokenToLocalStorage } from "@/utils/token";
import { Message } from "@arco-design/web-react";

// 协同获取表格数据
export async function getShare_doc(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/share_doc/get", {
      params,
    });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getScript(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/script/${params}`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
// 创建会话
export async function createSession(): Promise<any> {
  try {
    const res = await http.post("/api/v1/script/chat/session");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 复制脚本
export async function setScriptCopy(params, isHot): Promise<any> {
  const url = isHot ? "/api/v1/script/hot/copy" : "/api/v1/script/copy";
  try {
    const res = await http.post(url, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export const getScriptChat = async (value) => {
  const token = getTokenToLocalStorage();

  const readAndProcessResponse = async (response) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let buffer = "";

    async function readLine() {
      const contentType = response.headers.get("Content-Type");
      if (contentType.startsWith("application/json")) {
        const resp = await response.json();
        return resp;
      }
      let result = null;
      const followUps = [];
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          if (buffer) {
            // console.log("剩余行:", buffer);
          }
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        const lines = buffer.split("\n");
        buffer = lines.pop();
        lines.forEach((item, index) => {
          if (item.startsWith("event:")) {
            const eventType = item.split("event:")[1];
            if (
              eventType === "conversation.message.completed" &&
              index + 1 < lines.length
            ) {
              const dataStr = lines[index + 1];
              if (dataStr.startsWith("data:")) {
                const data = JSON.parse(dataStr.split("data:")[1]);
                if (data.type === "answer") {
                  result = {
                    event: eventType,
                    data: data,
                    followUp: followUps,
                  };
                } else if (data.type === "follow_up") {
                  followUps.push(data);
                }
              }
            }
          }
        });
      }
      if (result) {
        return {
          data: result.data.content,
          followUp: result.followUp,
        };
      }
      return null;
    }
    return readLine();
  };
  try {
    const response = await fetch(`/api/v1/script/chat`, {
      method: "POST",
      headers: {
        Token: `${token}`,
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(value),
    });

    return await readAndProcessResponse(response);
  } catch (e) {
    console.error("请求出错:", e);
    return null;
  }
};

export async function setScriptCount(params): Promise<any> {
  const id = params.id;
  delete params.id;
  try {
    const res = await http.put(`/api/v1/script/${id}/count`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
