import { makeAutoObservable, action, toJS, runInAction } from "mobx";
import { Message } from "@arco-design/web-react";
import { setTokenToLocalStorage } from "@/utils/token";
import { makePersistable } from "mobx-persist-store";
import {
  getSyntheticSubInfo,
  getSyntheticVideoList,
} from "@/services/taskCenter/taskList";
import _ from "lodash";
const groupByTaskNo = (data) => {
  const grouped = data.reduce((acc, item) => {
    const key = item.task_no;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return Object.values(grouped);
};
class VideoResult {
  videoResList = [];
  videoTimeLine = {
    id: 0,
    medias: [],
    rate: 0,
    time: "00:00",
    timeline: "",
    title: "",
  };
  task_no = [];
  videoInfo = {
    aspectRatio: "",
    coverUrl: "",
    duration: 0,
    mediaMarks: [],
    recommend: { bitrate: 0, width: 0, height: 0 },
    timeline: {},
    title: "精剪-3",
  };
  videoVisible = false;
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "VideoResult",
      properties: ["videoResList", "videoTimeLine", "task_no"],
      storage: window.localStorage,
    });
  }
  setTask_no = action(async (val) => {
    const nArr = [...this.task_no];
    nArr.push(val);
    this.task_no = nArr;
    this.getVideoResList(this.task_no);
  });

  filterTask_no = action((id) => {
    const nArr = [...this.task_no];
    this.task_no = nArr.filter((v) => v !== id);
    this.videoResList = this.videoResList.filter((v) => v.id !== id);
    // this.getVideoResList(this.task_no);
  });

  getVideoResList(task_no) {
    if (!task_no || !task_no.length) {
      this.videoResList = [];
      return;
    }
    setTimeout(async () => {
      const res = await getSyntheticSubInfo(JSON.stringify(this.task_no));
      if (res?.items) {
        const info = res.items.map((v, i) => ({
          ...v,
          name: v.title,
          id: v.timeline_id,
        }));

        let nArr = [...this.videoResList];
        const getVideoList = groupByTaskNo(info).map((v: any, i) => ({
          id: this.task_no[i],
          update_at: v[0].created_at * 1000,
          taskList: v.map((item) => ({
            ...item,
            progress: Math.floor(
              (v.filter((k) => k.status === 1).length / v.length) * 100,
            ),
          })),
          type: "compound",
        }));
        if (nArr.length) {
          getVideoList.forEach((item, i) => {
            if (item.id == nArr[i]?.id) {
              nArr[i] = {
                ...item,
                taskList: item.taskList.map((v, k) => ({
                  ...v,
                  isExamine: nArr[i].taskList[k]?.isExamine || false,
                })),
              };
            } else {
              nArr.push(item);
            }
          });
        } else {
          nArr = getVideoList;
        }
        this.videoResList = nArr;
      }
    }, 800);
  }
  clearExamine = action(() => {
    const nArr = [...this.videoResList];
    this.videoResList = nArr.map((item) => ({
      ...item,
      taskList: item.taskList.map((v) => ({ ...v, isExamine: true })),
    }));
  });

  setVideoVisible = action(() => {
    this.videoVisible = !this.videoVisible;
  });

  setVideoInfo = action((value) => {
    this.videoInfo = value;
  });
  setVideoTimeLine = action((value) => {
    this.videoTimeLine = value || {
      id: 0,
      medias: [],
      rate: 0,
      time: "00:00",
      timeline: "",
      title: "",
    };
  });
}
const VideoRes = new VideoResult();
export default VideoRes;
