import { editEngineering } from "@/services/createSpace/engineering";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  getToSencesInitTransitionItem,
  initCaptionItem,
  initISencesItem,
} from "@/stores/mixCutDetailStore/init";
import useTransition from "./useTransition";
import { IMaterialFile, ISences } from "@/stores/mixCutDetailStore/type";
import { ICaption } from "@/stores/mixCutDetailStore/type/caption";
import { Message } from "@arco-design/web-react";
import useTitle from "./useTitle";
import { IOpeatorTime } from "@/pages/VideoClips/MixCutDetail/VideoGroup/VideoGroupBlock/CutModal/CutModal";
import useMixCutDetailSave from "./useMixCutDetailSave";
import useMixCutDetail from "./useMixCutDetail";
import useCaption from "./useCaption";
import usePreview from "../preview/usePreview";
import { v4 as uuid } from "uuid";

interface IUseVideoGroupReturn {
  projectSences: ISences[];
  projectCaption: ICaption[][];
  setProjectSences: (sences: ISences[]) => void;
  handleProjectName: (name: string) => void;
  handleProjectSencesAdd: () => void;
  handleProjectSencesDelete: (index: number) => void;
  handleProjectVideoChange: (index: number, data: IMaterialFile[]) => void;
  handleChangeVideoItemStartAndDuration: (
    groupIndex: number,
    index: number,
    data: IOpeatorTime,
  ) => void;
  toIdGetIsRepeat: (id: string) => boolean;
}

const useVideoGroup = (): IUseVideoGroupReturn => {
  const { handleTransitionItemAdd, handleTransitionItemRemove } =
    useTransition();
  const { global } = useMixCutDetail();
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { handleTitleItemAdd, handleTitleItemRemove } = useTitle();
  const { handleCaptionItemDelete, handleCaptionItemAdd } = useCaption();
  const { findMaterialSetPreview } = usePreview();
  const toIdGetIsRepeat = (id: string) => {
    const allVideoMetarial = mixCutDetailStore
      .getProjectSences()
      .map((item) => item.materials)
      .flat(2);
    const sameIdLength = allVideoMetarial.filter(
      (item) => item.id === id,
    ).length;
    return sameIdLength > 1;
  };

  const handleChangeVideoItemStartAndDuration = (
    groupIndex: number,
    index: number,
    data: IOpeatorTime,
  ) => {
    const newMixCutDetailStoreSences = [
      ...mixCutDetailStore.getProjectSences(),
    ];
    newMixCutDetailStoreSences[groupIndex].materials[index].duration =
      data.duration;
    newMixCutDetailStoreSences[groupIndex].materials[index].start = data.start;
    newMixCutDetailStoreSences[groupIndex].materials[index].is_cropped = true;
    mixCutDetailStore.setProjectSences(newMixCutDetailStoreSences);
    handleSaveMixCutDetailSave();
  };

  const handleProjectName = async (name: string) => {
    const msg = mixCutDetailStore.getProjectMsg();
    try {
      await editEngineering({ ...msg, title: name });
      mixCutDetailStore.setProjectMsg({ ...msg, title: name });
      Message.success("名称修改成功！");
    } catch (e) {
      Message.error(e);
      console.log(e);
    }
  };

  const handleProjectSencesAdd = () => {
    if (mixCutDetailStore.getProjectSences().length === 15) {
      Message.error("镜头组最多15组，无法再次添加!");
    }
    const newSences: ISences[] = [
      ...mixCutDetailStore.getProjectSences(),
      { ...initISencesItem, id: uuid() },
    ];
    if (!global) {
      handleCaptionItemAdd();
      const [key, value] = Object.entries(
        getToSencesInitTransitionItem(newSences.length - 2),
      )[0];
      handleTransitionItemAdd(key, value);
    }
    mixCutDetailStore.setProjectSences(newSences);
    handleTitleItemAdd();
    handleSaveMixCutDetailSave();
  };

  const handleProjectSencesDelete = (index: number) => {
    const sences = mixCutDetailStore.getProjectSences();
    if (sences.length === 1) {
      Message.error("项目中必须保留一个镜头组");
      return;
    }
    const newSences = sences.filter((_, i) => i !== index);
    mixCutDetailStore.setProjectSences(newSences);
    if (!global) {
      handleTransitionItemRemove((index - 1).toString());
      handleTitleItemRemove(index);
      handleCaptionItemDelete(index);
    }
    findMaterialSetPreview();
    handleSaveMixCutDetailSave();
  };

  const handleProjectVideoChange = (index: number, data: IMaterialFile[]) => {
    mixCutDetailStore.getProjectSences()[index].materials = data;
    const newMixCutDetailStoreSences = [
      ...mixCutDetailStore.getProjectSences(),
    ];
    mixCutDetailStore.setProjectSences(newMixCutDetailStoreSences);
    findMaterialSetPreview();
    handleSaveMixCutDetailSave();
  };

  const setProjectSences = (e) => {
    mixCutDetailStore.setProjectSences(e);
    findMaterialSetPreview();
    handleSaveMixCutDetailSave();
  };

  return {
    projectCaption: mixCutDetailStore.getProjectCaption(),
    projectSences: mixCutDetailStore.getProjectSences(),
    setProjectSences: setProjectSences,
    handleProjectName,
    handleProjectSencesAdd,
    handleProjectSencesDelete,
    handleProjectVideoChange,
    handleChangeVideoItemStartAndDuration,
    toIdGetIsRepeat,
  };
};

export default useVideoGroup;
