import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  getToSencesInitTransitionItem,
  initCaptionItem,
  initISencesItem,
  initISencesSetting,
  initTitleTextSetting,
  toIndexGetInitTitleTextSetting,
} from "@/stores/mixCutDetailStore/init";
import { IOrigin, ISences } from "@/stores/mixCutDetailStore/type";
import useMixCutDetailSave from "./useMixCutDetailSave";
import useMaterialPlayInfo from "./useMaterialPlayInfo";
import { ITitle, ITitleItem } from "@/stores/mixCutDetailStore/type/title";
import { ICaption } from "@/stores/mixCutDetailStore/type/caption";
import useTransition from "./useTransition";
import { useEditorHelper } from "@/components/business/MyEditor/helper";
import usePreviewConfig from "../preview/usePreview";
import useMixCutDetailCheck from "./mixCutDetailCheck";
import useMixCutDetail from "./useMixCutDetail";
export interface useParseScriptReturn {
  parseCutsomScript: (data: string) => void;
  parseHotScipt: (data: string) => void;
  pureParseHotScript: (data: string) => IOrigin;
  pureParseCutsomScript: (data: string) => IOrigin;
}
const [helper] = useEditorHelper(null);
const useParseScript = (): useParseScriptReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { handleGetOriginJson } = useMixCutDetail();
  const { handlePreviewStore } = usePreviewConfig();
  const { toIdsSetPlayInfo } = useMaterialPlayInfo();
  const { handleErrorClear } = useMixCutDetailCheck();
  const { handleTransitionItemAdd } = useTransition();
  const pureParseCutsomScript = (data: string): IOrigin => {
    const parseData: IOrigin = {
      version: "1.0.0",
      _materials: null,
      global_settings: {
        enabled: null,
        titles: null,
        captions: null,
        transition: null,
        background: {
          audio: [],
          color: "white",
          images: [],
          audio_volume: 50,
        },
        cover: null,
      },
      scenes: null,
      transition: {} as any,
    };
    parseData.global_settings.enabled = false;
    const obj = JSON.parse(data).sort((a, b) => a.key - b.key);
    parseData._materials = {};
    parseData.scenes = obj.map((item, index) => {
      return {
        name: item.storyboardName[0].content[0].text,
        materials: (item.materials || []).map((material) => {
          parseData._materials = {
            ...parseData._materials,
            [material.id]: {
              url: material?.play_info?.play_url,
              cover: material?.play_info?.cover_url,
            },
          };
          return {
            media_id: material?.media_id,
            type: material?.type, // 1=视频,2=图片,3=音频,
            width: material?.width,
            height: material?.height,
            start: 0,
            duration: material?.duration,
            id: material?.id,
            is_cropped: false,
            speed: 1,
          };
        }),
        setting: {
          ...initISencesSetting,
        },
        title: {
          id: Math.floor(Date.now() + Math.random() * 10000),
          content: (item?.pictureDescription || []).map(() => {
            return {
              id: Math.floor(Date.now() + Math.random() * 10000),
              text: "",
              text_setting: { ...toIndexGetInitTitleTextSetting(index) },
              _text: [],
            };
          }),
        },
        caption: [
          {
            ...initCaptionItem,
            id: Math.floor(Date.now() + Math.random() * 10000),
            _content_json: item.caption,
            content: item.caption.map((step) => {
              return `<speak>${helper.formatJsonToSSML(step)}</speak>`;
            }),
            _preview: item.caption
              .map((step) => {
                return helper.formatJsonToSSML(step);
              })
              .join(" "),
          },
        ],
      };
    });
    for (let i = 0; i < parseData.scenes.length; i++) {
      parseData.transition[i] = Object.values(
        getToSencesInitTransitionItem(i),
      )[0];
    }
    return parseData;
  };

  const parseCutsomScript = async (data: string) => {
    handleErrorClear();
    mixCutDetailStore.setProjectIsGolbal(false);
    const obj = JSON.parse(data).sort((a, b) => a.key - b.key);
    mixCutDetailStore.setProjectMaterialsInfo({});
    const sences: ISences[] = obj.map((item) => {
      return {
        name: item.storyboardName[0].content[0].text,
        materials: (item.materials || []).map((material) => {
          const materialPlayInfos = mixCutDetailStore.getProjectMaterialsInfo();
          mixCutDetailStore.setProjectMaterialsInfo({
            ...(materialPlayInfos || {}),
            [material.id]: {
              url: material?.play_info?.play_url,
              cover: material?.play_info?.cover_url,
            },
          });
          return {
            media_id: material?.media_id,
            type: material?.type, // 1=视频,2=图片,3=音频,
            width: material?.width,
            height: material?.height,
            start: 0,
            duration: material?.duration,
            id: material?.id,
            is_cropped: false,
            speed: 1,
          };
        }),
        setting: {
          ...initISencesSetting,
        },
      };
    });
    if (
      Object.keys(mixCutDetailStore.getProjectMaterialsInfo() || {})?.length > 0
    ) {
      await toIdsSetPlayInfo(
        Object.keys(mixCutDetailStore.getProjectMaterialsInfo()),
      );
    }
    mixCutDetailStore.setProjectSences(sences);
    const titles: ITitleItem[] = obj.map((item, index) => {
      return {
        id: Math.floor(Date.now() + Math.random() * 10000),
        content: (item?.pictureDescription || []).map((desc) => {
          return {
            id: Math.floor(Date.now() + Math.random() * 10000),
            text: "",
            text_setting: { ...toIndexGetInitTitleTextSetting(index) },
            _text: [],
          };
        }),
      };
    });
    mixCutDetailStore.setProTitle(titles);
    const caption: ICaption[][] = obj.map((item) => {
      return [
        {
          ...initCaptionItem,
          id: Math.floor(Date.now() + Math.random() * 10000),
          _content_json: item?.caption,
          content: (item || []).caption.map((step) => {
            return `<speak>${helper.formatJsonToSSML(step)}</speak>`;
          }),
          _preview: (item || []).caption
            .map((step) => {
              return helper.formatJsonToSSML(step);
            })
            .join(" "),
        },
      ];
    });
    mixCutDetailStore.setProjectCaption(caption);
    generateTransition(caption.length - 1);
    handlePreviewStore(
      ((mixCutDetailStore.getProjectSences() || [])[0]?.materials || [])[0] ||
        null,
      ((mixCutDetailStore.getProjectCaption() || [])[0] || [])[0] || null,
      (titles || [])[0] || null,
    );
    return handleGetOriginJson();
  };

  const pureParseHotScript = (data: string): IOrigin => {
    const obj = JSON.parse(data);
    const parseData: IOrigin = {
      version: "1.0.0",
      _materials: null,
      global_settings: {
        enabled: null,
        titles: null,
        captions: null,
        transition: null,
        background: {
          audio: [],
          color: "white",
          images: [],
          audio_volume: 50,
        },
        cover: null,
      },
      scenes: null,
      transition: {} as any,
    };
    parseData.scenes = obj.section.map((item, index) => {
      return {
        caption: [
          {
            ...initCaptionItem,
            _content_json: [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: item.text,
                      },
                    ],
                  },
                ],
              },
            ],
            _preview: item.text,
            content: [`<speak>${item.text}</speak>`],
            id: Math.floor(Date.now() + Math.random() * 10000),
          },
        ],
        title: {
          id: Math.floor(Date.now() + Math.random() * 10000),
          content: [
            {
              _text: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "",
                    },
                  ],
                },
              ],
              text: "",
              text_setting: { ...toIndexGetInitTitleTextSetting(index) },
              id: Math.floor(Date.now() + Math.random() * 10000),
            },
          ],
        },
        name: item.title,
        ...initISencesItem,
      };
    });
    for (let i = 0; i < parseData.scenes.length; i++) {
      parseData.transition[i] = Object.values(
        getToSencesInitTransitionItem(i),
      )[0];
      return parseData;
    }
  };
  const parseHotScipt = async (data: string) => {
    handleErrorClear();
    const obj = JSON.parse(data);
    mixCutDetailStore.setProjectIsGolbal(false);
    const captions: ICaption[][] = obj.section.map((item) => {
      return [
        {
          ...initCaptionItem,
          _content_json: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: item.text,
                    },
                  ],
                },
              ],
            },
          ],
          _preview: item.text,
          content: [`<speak>${item.text}</speak>`],
          id: Math.floor(Date.now() + Math.random() * 10000),
        },
      ];
    });

    mixCutDetailStore.setProjectCaption(captions);
    const titles: ITitle = obj.section.map((item, index) => {
      return {
        id: Math.floor(Date.now() + Math.random() * 10000),
        content: [
          {
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
            text: "",
            text_setting: { ...toIndexGetInitTitleTextSetting(index) },
            id: Math.floor(Date.now() + Math.random() * 10000),
          },
        ],
      };
    });
    mixCutDetailStore.setProTitle(titles);
    generateTransition(obj.section.length - 1);
    mixCutDetailStore.setProjectSences(
      obj.section.map((item) => {
        return {
          ...initISencesItem,
          name: item.title,
        };
      }),
    );
    handlePreviewStore(
      null,
      ((captions || [])[0] || [])[0] || null,
      (titles || [])[0] || null,
    );
    handleSaveMixCutDetailSave();
  };

  const generateTransition = (length: number) => {
    mixCutDetailStore.setTransition({});
    for (let i = 0; i < length; i++) {
      const [entry] = Object.entries(getToSencesInitTransitionItem(i));
      handleTransitionItemAdd(entry[0], entry[1]);
    }
  };

  return {
    pureParseHotScript,
    pureParseCutsomScript,
    parseCutsomScript,
    parseHotScipt,
  };
};

export default useParseScript;
