import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { IOrigin } from "@/stores/mixCutDetailStore/type";
import { toJS } from "mobx";
import useMixCutDetailInit from "./useMixCutDetailInit";
import {
  getToSencesInitTransitionItem,
  initCaptionItem,
  toIndexGetInitTitleTextSetting,
} from "@/stores/mixCutDetailStore/init";
import previewConfing from "@/stores/previewStore";
import clipIdStore from "@/stores/previewStore/clipId";
import { v4 as uuid } from "uuid";
import Trasnsition from "@/pages/VideoClips/MixCutDetail/Transition/Transition";
import useTransition from "./useTransition";

// 获取所有json
// 开始是否global
// 设置ProjectMsg
const useMixCutDetail = () => {
  const { initGlobleCaptionGroup, initTitleGroup } = useMixCutDetailInit();
  const handelProjectGlobalChange = (e: boolean) => {
    mixCutDetailStore.setProjectIsGolbal(e);
  };
  const toggleGlobaltoItem = () => {
    previewConfing.setCaption(null);
    previewConfing.setTitle(null);
    clipIdStore.setClipId(null);
    mixCutDetailStore.setProjectIsGolbal(false);
    mixCutDetailStore.setTransition(null);
    (mixCutDetailStore.getProjectSences() || []).forEach((_, index) => {
      if (index === 0) {
        mixCutDetailStore.setProjectCaption([
          [
            {
              id: Math.floor(Date.now() + Math.random() * 100000),
              ...initCaptionItem,
            },
          ],
        ]);
        mixCutDetailStore.setProTitle([
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            content: [
              {
                id: Math.floor(Date.now() + Math.random() * 100000),
                text: "",
                _text: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: "",
                      },
                    ],
                  },
                ],
                text_setting: { ...toIndexGetInitTitleTextSetting(0) },
              },
            ],
          },
        ]);
      } else {
        const titles = mixCutDetailStore.getProjectTitle();
        const captions = mixCutDetailStore.getProjectCaption();
        mixCutDetailStore.setProTitle([
          ...titles,
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            content: [
              {
                id: Math.floor(Date.now() + Math.random() * 100000),
                text: "",
                _text: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: "",
                      },
                    ],
                  },
                ],
                text_setting: { ...toIndexGetInitTitleTextSetting(0) },
              },
            ],
          },
        ]);
        mixCutDetailStore.setProjectCaption([
          ...captions,
          [
            {
              id: Math.floor(Date.now() + Math.random() * 100000),
              ...initCaptionItem,
            },
          ],
        ]);
      }
      if (
        index !== 0 ||
        index !== mixCutDetailStore.getProjectSences().length
      ) {
        const transition = mixCutDetailStore.getTransition();
        mixCutDetailStore.setTransition({
          ...transition,
          ...getToSencesInitTransitionItem(index),
        });
      }
    });
  };

  const toggleItemtoGlobal = () => {
    mixCutDetailStore.setProjectIsGolbal(true);
    mixCutDetailStore.setProjectGlobalTransition({
      duration: 500,
      effects: [],
    });
    initGlobleCaptionGroup();
    initTitleGroup();
    previewConfing.setCaption(null);
    previewConfing.setTitle(null);
    clipIdStore.setClipId(null);
    const transition = {};
    mixCutDetailStore.getProjectSences().map((item, index) => {
      if (index > 0) {
        const [key, value] = Object.entries(
          getToSencesInitTransitionItem(index - 1),
        )[0];
        transition[key] = value;
      }
    });
    mixCutDetailStore.setTransition(transition);
  };

  const handleGetOriginJson = (): IOrigin => {
    const _materials = {};
    (
      Object.keys(mixCutDetailStore.getProjectMaterialsInfo() || {}) || []
    ).forEach((item) => {
      _materials[item] = {
        url: "",
        cover: "",
      };
    });
    return {
      version: mixCutDetailStore.getVerison() || "1.0.0",
      _materials,
      scenes: mixCutDetailStore.getProjectSences().map((item, index) => {
        return {
          id: item.id || uuid(),
          name: item.name,
          setting: item.setting,
          materials: item.materials.map((item) => {
            return {
              ...item,
              speed: 1,
            };
          }),
          caption: (mixCutDetailStore.getProjectCaption() || [])[index],
          title: (mixCutDetailStore.getProjectTitle() || [])[index],
        };
      }),
      transition: mixCutDetailStore.getTransition(),
      global_settings: {
        enabled: mixCutDetailStore.getProjectIsGlobale(),
        titles: mixCutDetailStore.getProjectTitle(),
        captions: mixCutDetailStore.getProjectGlobalCaptions(),
        transition: mixCutDetailStore.getProjectGlobalTransition(),
        cover: {
          images: toJS(mixCutDetailStore.getProjectCover()),
        },
        background: {
          audio: toJS(mixCutDetailStore.getProjectBgAudio()),
          color: toJS(mixCutDetailStore.getProjectBgColor()),
          images: toJS(mixCutDetailStore.getProjectBgImages()),
          audio_volume: mixCutDetailStore.getProjectBgAudioVolum() || 50,
        },
      },
    };
  };

  return {
    global: mixCutDetailStore.getProjectIsGlobale(),
    handelProjectGlobalChange,
    handleGetOriginJson,
    handleProjectMsgSet: mixCutDetailStore.setProjectMsg,
    projectMsg: mixCutDetailStore.getProjectMsg(),
    toggleGlobaltoItem,
    toggleItemtoGlobal,
  };
};

export default useMixCutDetail;
